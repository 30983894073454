// @ts-ignore
import Lottie from "react-lottie";

import * as loading from "../animations/loading_new.json";
import * as success from "../animations/animation_success.json";

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptions2 = {
  loop: true,
  autoplay: true,
  animationData: success,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function LoadingComponent(props: {
  loading: boolean;
  completed: boolean;
}) {
  const { loading, completed } = props;

  return (
    !completed ? (
      <>
      <div className="loading">
        <div className="screen-wallpaper">
          <div className="curved-background-box" style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div className="top-logos" style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingTop: '5px',
            }}>
              <div style={{ flex: 1 }}></div>
              <div style={{
                alignItems: 'center',
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
              }}>
                <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Neo Vultos" className="logo" />
              </div>
              <div style={{ flex: 1 }}>
                <img src={process.env.PUBLIC_URL + '/images/arete-logo.png'} alt="Арете" style={{
                  height: '50px',
                  marginRight: '10px',
                }} />
                <img src={process.env.PUBLIC_URL + '/images/school-logo.png'} alt="Арете" style={{
                  height: '50px',
                  marginRight: '10px',
                }} />
                <img src={process.env.PUBLIC_URL + '/images/viarity-logo.png'} alt="Арете" style={{
                  height: '50px',
                  marginRight: '10px',
                }} />
                <img src={process.env.PUBLIC_URL + '/images/am-logo.png'} alt="Арете" style={{
                  height: '50px',
                }} />
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
              zIndex: 1,
              flex: 1,
              marginBottom: '20px',
            }}>
              {!loading ? (
                <Lottie options={defaultOptions2} height={350} width={350} />
              ) : (
                <Lottie options={defaultOptions1} height={350} width={350} />
              )}
              <p style={{ color: '#fff', textShadow: '1px 1px #000', marginTop: '-30px', textAlign: 'center' }}>Моля,<br/>изчакайте докато се заредят ресурсите!</p>
            </div>
            <div className="footer-info">
              <div className="logos">
                <div className="nfk-logo-container">
                  <div style={{
                    color: '#fff',
                    fontSize: '12px',
                    marginBottom: '10px',
                    textShadow: '1px 1px #000'
                  }}>
                    Проектът е реализиран<br/>
                    с финансовата подкрепа на<br/>
                    Национален фонд<br/>
                    „Култура”
                  </div>
                  <img src={process.env.PUBLIC_URL + '/images/nfk-logo.png'} alt="НФК" />
                </div>
                {
                  [...Array(15)].map((_, index) => (
                    <img src={process.env.PUBLIC_URL + '/images/loading/thumb' + (index + 1) + '.png'} alt="Арете" key={`icons-thumbnails-${index}`}/>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="curved-background-container bottom"></div>
        </div>
      </div>
      </>
    ) : null
  );
}

export default LoadingComponent;
